<template>
  <div class="menu">
    <ClientOnly>
      <nuxt-link
        v-for="(item, index) in menuCategory"
        :id="`item-${index}`"
        :key="index"
        class="menu__item"
        :class="{ active: route.fullPath.includes(item.link.slice(1)), show: subMenuIndex === index }"
        :to="item.link"
        @click.prevent="onMouseLeave()"
        @mouseenter="onMouseOver(index)"
        @mouseleave="onMouseLeave()"
      >
        <span style="width: 24px">
          <BaseImg :src="item.icon" alt="icon-active" class="icon-active" />
        </span>
        <span>{{ item.title }}</span>
        <span v-if="item.subMenu.length > 0" class="icon-menu-down" />
        <div v-if="item.subMenu && item.subMenu.length" class="sub-menu">
          <div class="sub-menu__container" :class="{ isSlot: item.subMenu.length > 14 || item.subMenu.length === 8 }">
            <nuxt-link
              v-for="(subItem, subIndex) in item.subMenu"
              :key="`${index}${subIndex}`"
              class="sub-menu__item"
              :to="subItem.link"
              @click.prevent="onMouseLeave"
            >
              <BaseImg :src="subItem.image" alt="icon" />
            </nuxt-link>
          </div>
        </div>
      </nuxt-link>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { getMenuCategory } from '~/resources/menu-home-pc'
const route = useRoute()
const { menuCategory } = getMenuCategory()
const subMenuIndex = ref(null)

const onMouseOver = (index: any) => {
  subMenuIndex.value = index
  const element = document.getElementById('overlay-main')
  if (element) {
    element.style.visibility = 'visible'
    element.style.opacity = '1'
  }
}
const onMouseLeave = () => {
  subMenuIndex.value = null
  const element = document.getElementById('overlay-main')
  if (element) {
    element.style.visibility = 'hidden'
    element.style.opacity = '0'
  }
}
onMounted(() => {
  setTimeout(() => {
    doCalculations()
  }, 1000)
})

const doCalculations = () => {
  const menuLinkActive = document.querySelector('.menu__item.active') as HTMLElement
  if (menuLinkActive) {
    const menu = document.querySelector('.menu') as HTMLElement
    menu?.style.setProperty('--transformJS', `${menuLinkActive?.offsetLeft}px`)
    menu?.style.setProperty('--widthJS', `${menuLinkActive?.offsetWidth}px`)
  }
}
watch(
  () => route.fullPath,
  (value) => {
    setTimeout(() => {
      doCalculations()
    }, 100)
  }
)
</script>

<style lang="scss" scoped src="~/assets/scss/components/desktop/header/header.scss"></style>
