<template>
  <header id="header" class="wrapper-header">
    <div class="wrapper-header__content container-custom">
      <div class="wrapper-header__content--left">
        <nuxt-link
          id="logo"
          class="logo"
          :to="PAGE_URLS.HOME"
        >
          <BaseImg
            :src="LOGO_SITE_IMAGE"
            alt="logo"
          />
        </nuxt-link>
        <MenuCategory />
      </div>
      <client-only>
        <div class="wrapper-header__content--right">
          <UserNotLogin v-if="!currentUser" />
          <UserLogged v-else />
        </div>
      </client-only>
    </div>
  </header>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import { LOGO_SITE_IMAGE } from '~~/constants/menu'
import { PAGE_URLS } from '~/config/page-url'
import UserNotLogin from '~/components/desktop/header/user-not-login.vue'
import UserLogged from '~/components/desktop/header/user-logged.vue'
import MenuCategory from '~/components/desktop/header/menu.vue'
import BaseImg from '~/components/common/base-img.vue'

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { currentUser } = storeToRefs(store)
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/header/index.scss"></style>
