<template>
  <div class="user-not-login">
    <BaseButton
      id="login_btn"
      class="btn-login base-button--bg-orange"
      @click="onClickAuthor(MODAL_TYPES.LOGIN, 'login')"
    >
      Đăng nhập
    </BaseButton>
    <BaseButton
      id="register_btn"
      class="base-button--bg-orange btn-register"
      @click="onClickAuthor(MODAL_TYPES.LOGIN, 'register')"
    >
      Đăng ký
    </BaseButton>
  </div>
</template>
<script setup>
import BaseButton from '~/components/common/base-button'
import { useHeader } from '~/composables/useHeader'

const { onClickAuthor, MODAL_TYPES } = useHeader()
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/header/user-not-login.scss" />
