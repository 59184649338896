<template>
  <footer class="wrapper-footer">
    <div class="wrapper-footer__content container">
      <div class="wrapper-footer__top">
        <div class="wrapper-footer__top--left">
          <nuxt-link to="/" class="logo">
            <BaseImg src="/assets/brand/logo.webp" alt="logo" @click="goHome" />
          </nuxt-link>
          <BranchInfo />
        </div>
      </div>
      <div class="socials__wrapper border-bottom">
        <nuxt-link
          v-for="(item, index) in contact"
          :id="`socials-${item?.name}`"
          :key="index"
          :to="item.url"
          target="_blank"
        >
          <div class="item">
            <BaseImg :src="item.img" :alt="`icon-${item?.name}`" class="icon-contact" />
            <div class="content">
              <p class="title">{{ item.display_name }}</p>
              <p class="text">{{ item.content }}</p>
            </div>
          </div>
        </nuxt-link>
      </div>
      <div class="wrapper-footer__bottom">
        <p class="text">Copyright © {{ getFullYear }} Powered By Soibet All Rights Reserved.</p>
        <div class="menu">
          <template v-for="(item, index) in menuBottom" :key="index">
            <nuxt-link :to="item.to" class="menu__link">{{ item.title }}</nuxt-link>
            <div v-if="index !== menuBottom.length - 1" class="menu__line" />
          </template>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import BaseImg from '~/components/common/base-img.vue'
import { menuBottom } from '~/resources/footer'
import { PAGE_URLS } from '~/config/page-url'
import BranchInfo from '~~/components/common/branch-info.vue'
import { useAppStore } from '~/store/app'

const store = useAppStore()
const { contact } = storeToRefs(store)

const router = useRouter()

const getFullYear = computed(() => {
  return new Date().getFullYear()
})
const goHome = () => {
  router.push({ path: PAGE_URLS.HOME })
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/footer/index.scss" />
