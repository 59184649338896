import { PAGE_URLS } from '~~/config/page-url'

export const menuBottom = [
  {
    title: 'Về chúng tôi',
    to: PAGE_URLS.INTRODUCTION
  },
  {
    title: 'Chính sách',
    to: PAGE_URLS.POLICY
  },
  {
    title: 'Tin tức',
    to: PAGE_URLS.NEWS
  },
  {
    title: 'Khuyến mãi',
    to: PAGE_URLS.PROMOTION
  }
]
