<template>
  <div v-if="imageUrl" class="banner">
    <BaseImg :src="imageUrl" alt="hero-banner" />
    <div v-if="!isLiveCasino && sumJackpot['jackpotNohu']" class="jackpot">
      <AnimateCountUp :number="sumJackpot['jackpotNohu']" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { PAGE_URLS } from '~~/config/page-url'
import { useAppStore } from '~/store/app'
import AnimateCountUp from '~~/components/common/animate-count-up.vue'

const route = useRoute()
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

const isLiveCasino = computed(() => {
  return route.fullPath.includes(PAGE_URLS.CASINO)
})

const imageUrl = computed(() => {
  switch (true) {
    case route.fullPath.includes(PAGE_URLS.CASINO):
      return '/assets/images/components/desktop/pages/livecasino/banner.webp'
    case route.fullPath.includes(PAGE_URLS.GAME):
      return '/assets/images/components/desktop/pages/lobby-game/banner.webp'
    default:
      return ''
  }
})
</script>

<style scoped src="assets/scss/components/desktop/layouts/banner/index.scss" />
