<template>
  <div class="user-login">
    <div class="user-login__balance" :class="{ disabled: isShowModalDeposit }" @click="onNavDeposit">
      <BaseImg src="/assets/images/components/desktop/pages/home/header/icon-plus.svg" />
      <span class="user-login__balance--amount">{{ $formatNumberWithCommas(totalBalance, ',') }}</span>
      <span class="user-login__balance--unit">K</span>
    </div>
    <div class="user-login__info">
      <div class="user-login__info--top" @mouseenter="onMouseOver()" @mouseleave="onMouseLeave()">
        <div class="user-login__info--avatar">
          <BaseImg :src="currentAvatar" class="img-defaul img-fluid" />
          <BaseImg src="/assets/images/components/desktop/pages/account/avatar/icon-hamber.svg" class="img-hamber" />
        </div>
        <p class="user-login__info--fullname">{{ fullName }}</p>
        <div class="menu-bg" id="menu-bg">
          <div class="menu">
            <div class="menu__group-btn">
              <BaseButton class="button-deposit base-button--bg-golden" @click="onNavDeposit()">
                <p>NẠP</p>
              </BaseButton>
              <BaseButton class="button-withdraw base-button--bg-red" @click="onNavWithdraw()">
                <p>RÚT</p>
              </BaseButton>
            </div>
            <div class="menu__list">
              <div class="item" @click="openLink(ACCOUNT_URLS.PROFILE)">
                <BaseImg src="/assets/images/components/desktop/pages/home/header/menu/account.svg" />
                <span>Quản lí tài khoản</span>
              </div>
              <div class="item" @click="openLink(ACCOUNT_URLS.TRANSACTION_P2P)">
                <BaseImg src="/assets/images/components/desktop/pages/home/header/menu/icon-p2p.svg" />
                <span>Giao dịch P2P</span>
              </div>
              <div class="item" @click="openLink(ACCOUNT_URLS.TRANSACTION_HISTORY)">
                <BaseImg src="/assets/images/components/desktop/pages/home/header/menu/transaction-history.svg" />
                <span>Lịch sử</span>
              </div>
              <div class="item" @click="openLink(ACCOUNT_URLS.PROFIT_TODAY)">
                <BaseImg src="/assets/images/components/desktop/pages/home/header/menu/icon-bonus.svg" />
                <span>Tiền thưởng</span>
              </div>
              <div class="item" @click="logout()">
                <BaseImg src="/assets/images/components/desktop/pages/home/header/menu/logout.svg" />
                <span>Đăng xuất</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import BaseButton from '~/components/common/base-button.vue'
import { useLogout } from '~/composables/user/useLogout'
import { ACCOUNT_URLS } from '~/config/page-url'

const { $pinia, $formatNumberWithCommas } = useNuxtApp()
const store = useAppStore($pinia)
const { logout } = useLogout()
const { currentUser } = storeToRefs(store)
const isShowMenu = ref(false)
const isShowModalDeposit = ref(false)

const currentAvatar = computed(() => {
  return '/assets/images/common/avatar-pc-default.webp'
})

const fullName = computed(() => {
  return currentUser?.value?.fullname || currentUser?.value?.username || ''
})

const totalBalance = computed(() => {
  return currentUser?.value?.balance ?? 0
})

const onNavDeposit = () => {
  if (useRoute().path !== ACCOUNT_URLS.DEPOSIT_DA) {
    onMouseLeave()
    navigateTo(ACCOUNT_URLS.DEPOSIT_DA)
  }
}
const onNavWithdraw = () => {
  if (useRoute().path === ACCOUNT_URLS.WITHDRAW_BANK) {
    return
  }
  onMouseLeave()
  navigateTo(ACCOUNT_URLS.WITHDRAW_BANK)
}

const openLink = (url: string) => {
  if (useRoute().path !== url) {
    onMouseLeave()
    navigateTo(url)
  }
}

const onMouseOver = () => {
  const element = document.getElementById('menu-bg')
  if (element) {
    element.style.visibility = 'visible'
    element.style.opacity = '1'
  }
}
const onMouseLeave = () => {
  const element = document.getElementById('menu-bg')
  if (element) {
    element.style.visibility = 'hidden'
    element.style.opacity = '0'
  }
}
</script>
<style lang="scss" scoped src="assets/scss/components/desktop/header/user-logged.scss" />
