export function getMenuCategory() {
  const menuCategory = computed(() => {
    return [
      {
        id: 1,
        title: 'thể thao',
        link: '/the-thao',
        type: 'Thể thao',
        icon: '/assets/images/components/desktop/layouts/header/icon-sport.svg',
        subMenu: [
          {
            id: 1,
            image: '/assets/images/components/desktop/layouts/header/asports.webp',
            type: 'sport',
            link: '/the-thao/asports'
          },
          {
            id: 2,
            image: '/assets/images/components/desktop/layouts/header/ksports.webp',
            type: 'sport',
            link: '/the-thao/ksports'
          },
          {
            id: 3,
            image: '/assets/images/components/desktop/layouts/header/psports.webp',
            type: 'sport',
            link: '/the-thao/psports'
          },
          {
            id: 4,
            image: '/assets/images/components/desktop/layouts/header/esports.webp',
            type: 'sport',
            link: '/the-thao/esports'
          },
          {
            id: 5,
            image: '/assets/images/components/desktop/layouts/header/voltasport.webp',
            type: 'sport',
            link: '/the-thao/voltasports'
          },
          {
            id: 6,
            image: '/assets/images/components/desktop/layouts/header/virtual.webp',
            type: 'sport',
            link: '/the-thao/the-thao-ao'
          },
          {
            id: 7,
            image: '/assets/images/components/desktop/layouts/header/schedule.webp',
            link: '/the-thao/lich-thi-dau'
          }
        ]
      },
      {
        id: 2,
        title: 'Live Casino',
        icon: '/assets/images/components/desktop/layouts/header/icon-casino.svg',
        link: '/livecasino',
        type: 'live-casino',
        subMenu: []
      },
      {
        id: 3,
        title: 'Table games',
        icon: '/assets/images/components/desktop/layouts/header/icon-tablegame.svg',
        link: '/game/table-game',
        type: 'table-game',
        subMenu: []
      },
      {
        id: 4,
        title: 'quay số',
        icon: '/assets/images/components/desktop/layouts/header/icon-quayso.svg',
        link: '/game/quay-so',
        type: 'quay-so',
        subMenu: []
      },
      {
        id: 5,
        title: 'Game bài',
        icon: '/assets/images/components/desktop/layouts/header/icon-gamebai.svg',
        link: '/game/game-bai',
        type: 'game-bai',
        subMenu: []
      },
      {
        id: 6,
        title: 'Nổ hũ',
        link: '/game/no-hu',
        type: 'no-hu',
        icon: '/assets/images/components/desktop/layouts/header/icon-nohu.svg',
        subMenu: []
      },
      {
        id: 7,
        title: 'lô đề',
        icon: '/assets/images/components/desktop/layouts/header/icon-lode.svg',
        link: '/game/lo-de',
        type: 'lo-de',
        subMenu: []
      },
      {
        id: 8,
        title: 'Bắn cá',
        icon: '/assets/images/components/desktop/layouts/header/icon-banca.svg',
        link: '/game/ban-ca',
        type: 'ban-ca',
        subMenu: []
      },
      {
        id: 9,
        title: 'Game nhanh',
        icon: '/assets/images/components/desktop/layouts/header/icon-fastgame.svg',
        link: '/game/game-nhanh',
        type: 'game-nhanh',
        subMenu: []
      }
    ]
  })

  return {
    menuCategory
  }
}
