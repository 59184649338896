<template>
  <div class="brand-info">
    <div
      v-if="dataSeo?.content_title"
      ref="seoTitle"
      class="brand-info__title"
      v-html="$sanitize(dataSeo?.content_title)"
    />
    <div
      v-if="dataSeo?.content_description"
      ref="seoDes"
      class="brand-info__description"
      v-html="$sanitize(dataSeo?.content_description)"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useBase from '~/composables/useBase'
import { useAppStore } from '~/store/app'

useBase()

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { seoData } = storeToRefs(store)

const dataSeo = computed(() => {
  return seoData.value.find((item) => item.alias === '-' || item.alias === '/')
})
</script>

<style scoped lang="scss" src="assets/scss/components/common/branch-info.scss" />
